import axiosInstance from "../../axios";
import { TSelectedCompany } from "../../store";
import { TFeedbackForm } from "./types";
import { v4 as uuidv4 } from "uuid";

export const feedbacks = {
  create: async (data: TFeedbackForm, selectedCompany: TSelectedCompany) => {
    try {
      const response = await axiosInstance.post(
        `/forms/create-by-eld?eldType=${selectedCompany}`,
        {
          ...data,
        },
      );

      return response.data;
    } catch (error) {
      console.error("Deactivate error:", error);
      throw error;
    }
  },
  update: async (
    data: TFeedbackForm,
    formId: undefined | string | number,
    selectedCompany: TSelectedCompany,
  ) => {
    try {
      const response = await axiosInstance.put(
        `/forms/edit-by-eld/${formId}?eldType=${selectedCompany}`,
        {
          ...data,
        },
      );

      return response.data;
    } catch (error) {
      console.error("Deactivate error:", error);
      throw error;
    }
  },
  find: async (
    formId: number | string | undefined,
    selectedCompanys: TSelectedCompany,
  ): Promise<TFeedbackForm> => {
    try {
      const { data } = await axiosInstance.get<{ data: TFeedbackForm }>(
        `/forms/find-by-eld/${formId}?eldType=${selectedCompanys}`,
      );
      const questions = data.data.questions.map(item => {
        return {
          ...item,
          uid: uuidv4(),
          answers: item.answers?.map(answer => {
            return {
              ...answer,
              uid: uuidv4(),
            };
          }),
        };
      });
      return {
        ...data.data,
        questions,
      };
    } catch (error) {
      console.error("List error:", error);
      throw error;
    }
  },
  list: async (isActive: boolean, selectedCompany: TSelectedCompany) => {
    try {
      const response = await axiosInstance.get(
        `/forms/list-by-eld?isActive=${isActive}&eldType=${selectedCompany}`,
      );

      return response.data;
    } catch (error) {
      console.error("List error:", error);
      throw error;
    }
  },
  deactivate: async (formId: number, selectedCompany: TSelectedCompany) => {
    try {
      const response = await axiosInstance.patch(
        `/forms/update-by-eld/${formId}?eldType=${selectedCompany}`,
        {
          isActive: false,
        },
      );

      return response.data;
    } catch (error) {
      console.error("Deactivate error:", error);
      throw error;
    }
  },
  reactivate: async (formId: number, selectedCompany: TSelectedCompany) => {
    try {
      const response = await axiosInstance.patch(
        `/forms/update-by-eld/${formId}?eldType=${selectedCompany}`,
        {
          isActive: true,
        },
      );

      return response.data;
    } catch (error) {
      console.error("Deactivate error:", error);
      throw error;
    }
  },
  remove: async (formId: number, selectedCompany: TSelectedCompany) => {
    try {
      const response = await axiosInstance.delete(
        `/forms/delete-by-eld/${formId}?eldType=${selectedCompany}`,
      );

      return response.data;
    } catch (error) {
      console.error("Remove error:", error);
      throw error;
    }
  },
  deleteAnswer: async (
    questionnaireId: number,
    selectedCompany: TSelectedCompany,
  ) => {
    try {
      const response = await axiosInstance.delete(
        `questionnaires/delete-by-eld/${questionnaireId}/?eldType=${selectedCompany}`,
      );

      return response.data;
    } catch (error) {
      console.error("Remove error:", error);
      throw error;
    }
  },
  listQuestionnaires: async (
    formId: number,
    page: number,
    selectedCompany: TSelectedCompany,
  ) => {
    try {
      const response = await axiosInstance.get(
        `questionnaires/list-by-eld?formId=${formId}&perPage=10&page=${page}&eldType=${selectedCompany}`,
      );
      return response.data;
    } catch (error) {
      console.error("List error:", error);
      throw error;
    }
  },
  listQuestionnairesCompany: async (
    id: number,
    selectedCompany: TSelectedCompany,
  ) => {
    try {
      const response = await axiosInstance.get(
        `surveys/list-by-eld/${id}?eldType=${selectedCompany}`,
      );
      response?.data?.data?.sort(
        (a: any, b: any) => a.orderNumber - b.orderNumber,
      );

      return response.data;
    } catch (error) {
      console.error("List error:", error);
      throw error;
    }
  },
  listStatistics: async (formId: number, selectedCompany: TSelectedCompany) => {
    try {
      const response = await axiosInstance.get(
        `/questions/statistics-by-eld?formId=${formId}&eldType=${selectedCompany}`,
      );
      response?.data?.data?.sort(
        (a: any, b: any) => a.orderNumber - b.orderNumber,
      );
      return response.data;
    } catch (error) {
      console.error("List error:", error);
      throw error;
    }
  },
  listAnswers: async (
    questionId: number,
    page: number,
    selectedCompany: TSelectedCompany,
  ) => {
    try {
      const response = await axiosInstance.get(
        `/surveys/list?questionId=${questionId}&perPage=10&page=${page}&isOther=true`,
      );

      return response.data;
    } catch (error) {
      console.error("List error:", error);
      throw error;
    }
  },
};
