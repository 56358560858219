import { useQuery } from "react-query";
import { statistics } from "../../api/statistics";
import moment from "moment";
import { useGlobalStore } from "../../store";

export const useStatisticsList = (startDate: string, endDate: string) => {
  const selectedCompany = useGlobalStore(state => state.selectedCompany);
  return useQuery(
    [
      "statistics",
      {
        startDate: moment(startDate).format("DD-MM-YYYY"),
        endDate: moment(endDate).format("DD-MM-YYYY"),
        selectedCompany,
      },
    ],
    () =>
      startDate &&
      endDate &&
      selectedCompany &&
      statistics.list(startDate, endDate, selectedCompany),
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );
};
