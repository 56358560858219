import moment from "moment";
import { TStatistics } from "../../api/statistics/types";
import { TCard, TStatisticsCard } from "./types";

export const selectColor = (el: string) => {
  let color;
  switch (el) {
    case "inactiveTotal":
      color = "#F99E2C";
      break;
    case "activeTotal":
    case "loginTotal":
    case "acceptedTotal":
      color = "#27AE60";
      break;
    case "trialTotal":
      color = "#797B8D";
      break;
    case "increaseTotal":
    case "increaseCompanyTotal":
      color = "#27AE60";
      break;
    case "rejectedTotal":
      color = "#F64747";
      break;
    case "newTotal":
    case "updateTotal":
    case "updateCompanyTotal":
      color = "#409CFF";
      break;
    case "loginTotal":
      color = "#9B51E0";
      break;
    default:
      color = el.includes("active") ? "#27AE60" : "#F99E2C";
  }
  return color;
};
const cardLabels = {
  activeTotal: "Active",
  inactiveTotal: "Inactive",
  trialTotal: "Free trails",
  increaseTotal: "Increases",
  deacreaseTotal: "Decreases",
  increaseCompanyTotal: "Increases",
  decreaseCompanyTotal: "Decreases",
  loginTotal: "Log ins",
  logoutTotal: "Log outs",
  newTotal: "New registered",
  acceptedTotal: "Approved",
  rejectedTotal: "Rejected",
  updateTotal: "Total updates",
  updateCompanyTotal: "Total updated companies",
};
export const cardBuilder = (
  data: TStatistics,
  title: string,
  headerTitle: string,
  cards: TCard[],
  chartKeys: string[],
): TStatisticsCard => {
  return {
    title,
    header: {
      title: headerTitle,
      total: parseFloat(data.total.toFixed(1)),
      newTotal: parseFloat(data.totalDifference.toFixed(1)),
      newPercentage: parseFloat(data.totalPercentage.toFixed(1)),
      statResultText: "",
    },
    cards,
    chart: {
      lines: !data?.allData?.length
        ? []
        : Object.keys(data?.allData[0]?.data)
            ?.filter(el => {
              if (chartKeys.includes(el)) {
                return el;
              }
            })
            ?.map(el => {
              const color = selectColor(el);
              return {
                key: el,
                color,
              };
            }),
      data: data.allData?.map((el, i) => {
        const firsData = data.allData[0];
        let date = moment(el.date).format("ddd, DD MMM");
        if (
          i &&
          firsData &&
          moment(firsData.date).diff(moment(el.date), "month")
        ) {
          date = moment(el.date).format("DD, MMM");
        }
        if (
          i &&
          firsData &&
          moment(firsData.date).diff(moment(el.date), "year")
        ) {
          date = moment(el.date).format("DD, MMM, YYYY");
        }
        return {
          ...el.data,
          name: date,
          title: date,
          cardLabels,
        };
      }),
    },
  };
};

export const vehicleAndDriversCard = (data: TStatistics): TCard[] => {
  return [
    {
      title: "New",
      total: parseFloat(data.newTotal.toFixed(1)),
      newPercentage: parseFloat(data.newPercentage.toFixed(1)),
      newTotal: parseFloat(data.newDifference.toFixed(1)),
      color: "blue",
      background: "rgba(64, 156, 255, 0.10)",
    },
    {
      title: "Active",
      total: parseFloat(data.activeTotal.toFixed(1)),
      newPercentage: parseFloat(data.activePercentage.toFixed(1)),
      newTotal: parseFloat(data.activeDifference.toFixed(1)),
      color: "green",
      background: "var(--Light-mode-Grey-01)",
    },
    {
      title: "Deactivated",
      total: parseFloat(data.inactiveTotal.toFixed(1)),
      newPercentage: parseFloat(data.inactivePercentage.toFixed(1)),
      newTotal: parseFloat(data.inactiveDifference.toFixed(1)),
      color: "orange",
      background: "var(--Light-mode-Grey-01)",
    },
  ];
};

export const companiesCard = (data: TStatistics): TCard[] => {
  return [
    {
      title: "Active companies",
      total: parseFloat(data.activeTotal.toFixed(1)),
      newPercentage: parseFloat(data.activePercentage.toFixed(1)),
      newTotal: parseFloat(data.activeDifference.toFixed(1)),
      color: "green",
      background: "var(--Light-mode-Grey-01)",
    },
    {
      title: "Inactive companies",
      total: parseFloat(data.inactiveTotal.toFixed(1)),
      newPercentage: parseFloat(data.inactivePercentage.toFixed(1)),
      newTotal: parseFloat(data.inactiveDifference.toFixed(1)),
      color: "orange",
      background: "rgba(249, 158, 44, 0.1)",
    },
    {
      title: "New companies",
      total: parseFloat(data.newTotal.toFixed(1)),
      newPercentage: parseFloat(data.newPercentage.toFixed(1)),
      newTotal: parseFloat(data.newDifference.toFixed(1)),
      color: "blue",
      background: "rgba(64, 156, 255, 0.10)",
    },
    {
      title: "Cancelled companies",
      total: parseFloat((data.cancelledTotal || 0).toFixed(1)),
      newPercentage: parseFloat((data.cancelledPercentage || 0).toFixed(1)),
      newTotal: parseFloat((data.cancelledDifference || 0).toFixed(1)),
      color: "red",
      background: "rgba(246, 71, 71, 0.1)",
    },
    {
      title: "Free trials",
      total: parseFloat((data.freeTrialTotal || 0).toFixed(1)),
      newPercentage: parseFloat((data.freeTrialPercentage || 0).toFixed(1)),
      newTotal: parseFloat((data.freeTrialDifference || 0).toFixed(1)),
      color: "grey",
      background: "rgba(245, 245, 248, 1)",
    },
  ];
};

export const subscriptionQuantity = (data: TStatistics): TCard[] => {
  return [
    {
      title: "Total updates",
      total: parseFloat((data.updateTotal || 0).toFixed(1)),
      newPercentage: parseFloat((data.updatePercentage || 0).toFixed(1)),
      newTotal: parseFloat((data.updateDifference || 0).toFixed(1)),
      color: "blue",
      background: "rgba(64, 156, 255, 0.10)",
    },
    {
      title: "Increases",
      total: parseFloat((data.increaseTotal || 0).toFixed(1)),
      newPercentage: parseFloat((data.increasePercentage || 0).toFixed(1)),
      newTotal: parseFloat((data.increaseDifference || 0).toFixed(1)),
      color: "green",
      background: "rgba(39, 174, 96, 0.1)",
    },
    {
      title: "Decreases",
      total: parseFloat((data.decreaseTotal || 0).toFixed(1)),
      newPercentage: parseFloat((data.decreasePercentage || 0).toFixed(1)),
      newTotal: parseFloat((data.decreaseDifference || 0).toFixed(1)),
      color: "orange",
      background: "rgba(249, 158, 44, 0.1)",
    },
  ];
};

export const companySubscriptionsUpdate = (data: TStatistics): TCard[] => {
  return [
    {
      title: "Total updated companies",
      total: parseFloat((data.updateTotal || 0).toFixed(1)),
      newPercentage: parseFloat((data.updatePercentage || 0).toFixed(1)),
      newTotal: parseFloat((data.updateDifference || 0).toFixed(1)),
      color: "blue",
      background: "rgba(64, 156, 255, 0.10)",
    },
    {
      title: "Increases",
      total: parseFloat((data.increaseTotal || 0).toFixed(1)),
      newPercentage: parseFloat((data.increasePercentage || 0).toFixed(1)),
      newTotal: parseFloat((data.increaseDifference || 0).toFixed(1)),
      color: "green",
      background: "rgba(39, 174, 96, 0.1)",
    },
    {
      title: "Decreases",
      total: parseFloat((data.decreaseTotal || 0).toFixed(1)),
      newPercentage: parseFloat((data.decreasePercentage || 0).toFixed(1)),
      newTotal: parseFloat((data.decreaseDifference || 0).toFixed(1)),
      color: "orange",
      background: "rgba(249, 158, 44, 0.1)",
    },
  ];
};

export const userDataCards = (data: TStatistics): TCard[] => {
  return [
    {
      title: "New registered",
      total: parseFloat((data.newTotal || 0).toFixed(1)),
      newPercentage: parseFloat((data.newPercentage || 0).toFixed(1)),
      newTotal: parseFloat((data.newDifference || 0).toFixed(1)),
      color: "blue",
      background: "rgba(64, 156, 255, 0.10)",
    },
    {
      title: "Approved",
      total: parseFloat((data.acceptedTotal || 0).toFixed(1)),
      newPercentage: parseFloat((data.acceptedPercentage || 0).toFixed(1)),
      newTotal: parseFloat((data.acceptedPercentage || 0).toFixed(1)),
      color: "green",
      background: "#27AE601A",
    },
    {
      title: "Rejected",
      total: parseFloat((data.rejectedTotal || 0).toFixed(1)),
      newPercentage: parseFloat((data.rejectedPercentage || 0).toFixed(1)),
      newTotal: parseFloat((data.rejectedDifference || 0).toFixed(1)),
      color: "red",
      background: "rgba(246, 71, 71, 0.10)",
    },
    {
      title: "Log ins",
      total: parseFloat((data.loginTotal || 0).toFixed(1)),
      newPercentage: parseFloat((data.loginPercentage || 0).toFixed(1)),
      newTotal: parseFloat((data.loginDifference || 0).toFixed(1)),
      color: "purple",
      background: "var(--Light-mode-Grey-01)",
    },
    {
      title: "Log outs",
      total: parseFloat((data.logoutTotal || 0).toFixed(1)),
      newPercentage: parseFloat((data.logoutPercentage || 0).toFixed(1)),
      newTotal: parseFloat((data.logoutDifference || 0).toFixed(1)),
      color: "orange",
      background: "var(--Light-mode-Grey-01)",
    },
  ];
};
