import React, { useState } from "react";
import "./App.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Sidebar from "./components/layouts/sidebar/sidebar";
import Navbar from "./components/layouts/navbar/navbar";
import FeedbacksPage from "./components/feedbacks/FeedbacksPage";
import Login from "./components/auth/Login";
import AuthCheck from "./HOC/AuthCheck";
import { QueryClient, QueryClientProvider } from "react-query";
import { ConfirmProvider } from "./providers/ConfirmProvider";
import ConfirmModal from "./components/confirmModal";
import FeedbacksForm from "./components/feedbacks/components/feedbacksForm/feedbacksForm";
import { AlertProvider } from "./providers/Alert/AlertContext";
import FeedbacksFormEdit from "./components/feedbacks/components/feedbacksForm/feedbacksFormEdit";
import OverviewPage from "./components/overview/OverviewPage";
import { useGlobalStore } from "./store";
const queryClient = new QueryClient();

function App() {
  const sidebarOpen = useGlobalStore(state => state.sidebarOpen);
  const toggleSidebar = useGlobalStore(state => state.toggleSidebar);
  return (
    <AlertProvider>
      <QueryClientProvider client={queryClient}>
        <ConfirmProvider>
          <Router>
            <AuthCheck>
              <div className='app'>
                <ConfirmModal />
                <Routes>
                  <Route path='/auth/login' element={<Login />} />
                  <Route
                    path='*'
                    element={
                      <>
                        <Sidebar
                          sidebarOpen={sidebarOpen}
                          setSidebarOpen={toggleSidebar}
                        />
                        <div
                          className='content'
                          style={{
                            marginLeft: sidebarOpen ? 240 : 68,
                          }}
                        >
                          <Navbar
                            setSidebarOpen={toggleSidebar}
                            sidebarOpen={sidebarOpen}
                          />
                        </div>
                      </>
                    }
                  />
                </Routes>
                <div
                  className='content'
                  style={{
                    marginLeft: sidebarOpen ? 240 : 68,
                  }}
                >
                  <Routes>
                    <Route path='/feedbacks' element={<FeedbacksPage />} />
                    <Route
                      path='/feedbacks/create'
                      element={<FeedbacksForm />}
                    />
                    <Route
                      path='/feedbacks/edit/:id'
                      element={<FeedbacksFormEdit />}
                    />
                    <Route path='/' element={<OverviewPage />} />
                  </Routes>
                </div>
              </div>
            </AuthCheck>
          </Router>
        </ConfirmProvider>
      </QueryClientProvider>
    </AlertProvider>
  );
}

export default App;
